import React, { useEffect } from "react";
import NavBarAdmin from "../../components/Admin/Layout/NavBarAdmin";
import { ToastContainer } from "react-toastify";
import InvoicesAdminTable from "../../components/Admin/Table/InvoicesAdminTable";
import { getInvoices } from "../../services/Api/partnerService";
import { useParams } from "react-router-dom";
import { useInvoiceStore } from "../../stores/admin/invoices";

const AdminInvoices = () => {
  const { idPartner } = useParams();
  const { setInvoices } = useInvoiceStore();

  useEffect(() => {
    getInvoices(Number(idPartner))
      .then((data) => setInvoices(data))
      .catch((error) => console.log(error));
  }, [idPartner]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex flex-col">
      <div className="flex h-full w-full">
        <NavBarAdmin />
        <ToastContainer position="top-center" />
        <div className="flex flex-col justify-start items-center w-full h-full">
          <div className="w-10/12 h-full">
            <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D 2xl:py-5 py-2">
              Vos factures :{" "}
            </h2>
            <div className="w-full h-[90%] rounded-md flex">
              <InvoicesAdminTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminInvoices;
