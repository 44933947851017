import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTokenContext } from "../../../context/tokenContext";
import { useServiceStore } from "../../../stores/admin/service";
import { customersByIdPartner } from "../../../services/Api/partnerService";
import { usePartnerHomeCustomerStore } from "../../../stores/Cac/Partner/partnerHomeCustomer";
import PartnerCustomerTable from "../../../components/Cac/Partner/ClientFile/PartnerCustomerTable";
import Return from "../../../assets/icons/return.svg";
import SearchBar from "../../../components/Admin/Services/SearchBar";
import PartnerModalCreateUpdateCustomer from "../../../components/Cac/Partner/ClientFile/PartnerModalCreateUpdateCustomer";
import PartnerDeleteCustomer from "../../../components/Cac/Partner/ClientFile/PartnerDeleteCustomer";
import { ToastContainer } from "react-toastify";
import { Notifications } from "react-push-notification";
import SideBar from "../../../components/Layout/SideBar";

const PartnerHomeCustomer: React.FC = () => {
  // Context
  const { roleId, isSuperAdmin } = useTokenContext();

  // Hooks
  const navigate = useNavigate();
  const { idPartner } = useParams();

  // Store
  const { setClients, choiceModal, setChoiceModal } =
    usePartnerHomeCustomerStore();
  const { searchTerm } = useServiceStore();

  // State
  const [allClients, setAllClients] = useState<any>([]);

  useEffect(() => {
    if (idPartner) {
      if (choiceModal === "create" || choiceModal === "update") return;
      customersByIdPartner(Number(idPartner))
        .then((data) => {
          const clientsWithFullName = data?.map((client: any) => ({
            ...client,
            fullName: client.User.fullName,
          }));
          setAllClients(clientsWithFullName);
          setClients(clientsWithFullName);
        })
        .catch((error) => {
          console.error("Erreur lors de la récupération des clients:", error);
        });
    }
  }, [choiceModal]);

  useEffect(() => {
    const filteredClients = searchTerm
      ? allClients.filter(
          (client: any) =>
            client.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            client.fullName.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : allClients;
    setClients(filteredClients);
  }, [searchTerm, allClients]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed flex justify-start items-start">
      <SideBar />
      <Notifications />
      <ToastContainer position="top-center" />
      <div className="flex flex-col justify-start items-center w-10/12 h-screen">
        <div className="w-11/12 flex justify-start sm:justify-between items-center 3xl:my-5 xl:my-3">
          <h2 className="my-2 text-nxm-darkGray font-light 2xl:text-3xl text-xl mr-5 ml-1 font-K2D ">
            Liste de vos clients :
          </h2>
          <button
            type="button"
            className={
              roleId === 2
                ? "hidden"
                : "hidden xl:w-44 xl:h-10 xl:flex mt-5 xl:mb-2 items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-white font-K2D text-md"
            }
            onClick={() => {
              setChoiceModal("create");
            }}
          >
            + Créer un client{" "}
          </button>
          {choiceModal === "create" || choiceModal === "update" ? (
            <PartnerModalCreateUpdateCustomer />
          ) : choiceModal === "delete" ? (
            <PartnerDeleteCustomer />
          ) : null}
        </div>
        <div className="hidden md:flex justify-around items-center w-11/12 pt-2 2xl:mb-10 mb-3">
          <div className="hidden md:flex justify-start items-center w-full mb-2">
            <button
              type="button"
              className="flex mr-4"
              onClick={() =>
                isSuperAdmin === 1
                  ? navigate("/superadmin/dashboard")
                  : navigate("/dashboard")
              }
              title="Retour"
            >
              <img src={Return} alt="return" />
            </button>
            <div className="flex sm:flex-row flex-col items-center justify-center">
              <SearchBar />
            </div>
          </div>
          <div className="flex justify-between items-center ">
            <button
              type="button"
              className={
                roleId === 2
                  ? "hidden"
                  : "hidden xl:w-44 xl:h-8 xl:flex items-center justify-center rounded-md border-[1px] border-gray-300 p-2 hover:bg-nxm-darkBlue hover:text-white transition-all duration-500 bg-white font-K2D text-sm"
              }
              onClick={() =>
                navigate(`/partner/${idPartner}/monitoring/missions`)
              }
            >
              <p>Monitoring Missions</p>
            </button>
          </div>
        </div>
        <div className="w-11/12 border-2 border-zinc-100 h-[90%] md:h-[75%] rounded-md bg-white mb-20">
          <PartnerCustomerTable />
        </div>
      </div>
    </div>
  );
};

export default PartnerHomeCustomer;
