import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Return from "../../../assets/icons/return.svg";
import CardMission from "../../../components/Cac/Partner/Utils/MonitoringElements/CardMission";
import useSorting from "../../../hooks/useSorting";
import {
  MissionMonitoring,
  colorPriority,
} from "../../../interfaces/missionsMonitoring";
import FilterManagerMonitoringMission from "../../../components/Cac/Partner/Utils/MonitoringElements/FilterManagerMonitoringMission";
import FilterCategoryMonitoringMission from "../../../components/Cac/Partner/Utils/MonitoringElements/FilterCategoryMonitoringMission";
import { getMissionsOverviewByIdPartner } from "../../../services/Api/partnerService";
import { ToastContainer } from "react-toastify";
import { Notifications } from "react-push-notification";
import SideBar from "../../../components/Layout/SideBar";

const PartnerMonitoringMission = () => {
  const navigate = useNavigate();
  const [missions, setMissions] = useState<MissionMonitoring[]>([]);
  const [managers, setManagers] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState<MissionMonitoring[]>([]);
  const [selectedResponsable, setSelectedResponsable] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [sortedData, handleSort] = useSorting(missions);
  const { idPartner } = useParams();

  useEffect(() => {
    if (idPartner)
      getMissionsOverviewByIdPartner(Number(idPartner))
        .then((data) => {
          // Sort missions by color priority
          data?.sort(
            (a: MissionMonitoring, b: MissionMonitoring) =>
              colorPriority[a.color] - colorPriority[b.color]
          );
          setMissions(data);

          const newManagers = [
            ...new Set(
              data?.map((mission: any) => mission?.fullName) as string[]
            ),
          ];
          const newCategories = [
            ...new Set(
              data?.map((mission: any) => mission?.categoryMission) as string[]
            ),
          ];
          setManagers(newManagers);
          setCategories(newCategories);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);

  useEffect(() => {
    let tempData = [...sortedData]; // Copy of sortedData

    if (selectedResponsable) {
      tempData = tempData.filter(
        (mission) => mission?.fullName === selectedResponsable
      );
    }

    if (selectedCategory) {
      tempData = tempData.filter(
        (mission) => mission?.categoryMission === selectedCategory
      );
    }

    // Seulement appeler setFilteredData si tempData a changé
    if (JSON.stringify(tempData) !== JSON.stringify(filteredData)) {
      setFilteredData(tempData);
    }
  }, [sortedData, selectedResponsable, selectedCategory, filteredData]);

  const handleResetFilter = () => {
    setSelectedResponsable("");
    setSelectedCategory("");
  };

  return (
    <div className="w-full h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex">
      <ToastContainer position="top-center" />
      <Notifications />
      <SideBar />
      <div className="flex flex-col justify-start items-center w-full">
        <div className="w-9/12 pt-5 flex flex-col justify-between items-center">
          <h2 className="text-gray-700 font-bold text-xl sm:text-4xl mt-5">
            Monitoring des missions :
          </h2>
        </div>
        <div className="flex justify-between m-auto w-10/12 overflow-y-auto mb-8 xl:mb-">
          <div className="flex absolute">
            <button
              type="button"
              className="flex mr-2 xl:mr-10"
              title="Retour"
              onClick={() => navigate(-1)}
            >
              <img src={Return} alt="return" />
            </button>
            <p className="pr-4">Filtrer par :</p>
            <div className="left-5 xl:left-20 mr-2">
              <FilterManagerMonitoringMission
                managers={managers}
                selectedResponsable={selectedResponsable}
                setSelectedResponsable={setSelectedResponsable}
              />
            </div>
            <div className="mr-2">
              <FilterCategoryMonitoringMission
                categories={categories}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            </div>
            <button
              type="button"
              onClick={() => handleSort("dueDate")}
              className="hidden mr-2 sm:w-42 sm:h-6 text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-filter-blue hover:text-white transition-all duration-500 bg-white"
            >
              <p>Date</p>
            </button>
            <button
              type="button"
              onClick={() => handleSort("progress")}
              className="hidden mr-2  sm:w-42 sm:h-6 text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-filter-blue hover:text-white transition-all duration-500 bg-white"
            >
              <p>Progression</p>
            </button>
            <button
              type="button"
              onClick={() => handleSort("color")}
              className="hidden mr-2 sm:w-42 sm:h-6 text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-filter-blue hover:text-white transition-all duration-500 bg-white"
            >
              <p>Urgence</p>
            </button>
            <button
              type="button"
              onClick={() => handleSort("dateExercice")}
              className="hidden mr-2 sm:w-42 sm:h-6 text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-filter-blue hover:text-white transition-all duration-500 bg-white"
            >
              <p>Année fiscale</p>
            </button>
            <button
              type="button"
              onClick={() => handleResetFilter()}
              className="hidden mr-2 sm:w-42 sm:h-6 text-sm sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-filter-blue hover:text-white transition-all duration-500 bg-white"
            >
              <p>Reset filtre</p>
            </button>
          </div>
        </div>
        <></>
        {/*  <div className="w-10/12 m-auto hidden xl:flex items-center justify-end">
          <div className="w-6 h-3 border-[1px] rounded-md border-green-300  bg-green-300 mr-2"></div>
          <p className="mr-4 font-extralight">Terminé</p>
          <div className="w-6 h-3 border-[1px] rounded-md border-green-200  bg-green-200 mr-2"></div>
          <p className="mr-4 font-extralight">En cours</p>
          <div className="w-6 h-3 border-[1px] rounded-md border-yellow-200 bg-yellow-200 mr-2"></div>
          <p className="mr-4 font-extralight">Urgent</p>
          <div className="w-6 h-3 border-[1px] rounded-md border-red-300 bg-red-300 mr-2"></div>
          <p className="mr-4 font-extralight">Expiré</p>
          <div className="w-6 h-3 border-[1px] rounded-md border-gray-300 bg-gray-300 mr-2"></div>
          <p className="mr-4 font-extralight">Non débuté</p>
        </div> */}
        <div className="w-10/12 bg-white rounded-md h-[75%] m-auto mt-2 border-[1px] shadow-lg">
          <div className="grid grid-cols-5 w-full mx-2 overflow-y-scroll h-full justify-items-center align-items-center gap-2">
            {filteredData?.map((mission: any, index: any) => (
              <CardMission key={index} mission={mission} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerMonitoringMission;
