import { useState, useCallback } from "react";

type SortDirection = "asc" | "desc";

interface SortingOptions<T> {
  field: keyof T | string; // Autoriser les sous-propriétés en utilisant `string`
  direction: SortDirection;
}

// Définir l'ordre des statuts
const statusOrder: { [key: string]: number } = {
  Délivrée: 1,
  "En retard": 2,
  "En Attente": 3,
  Accepté: 4, // Si le statut "Accepté" est ajouté plus tard
};

const colorOrder: { [key: string]: number } = {
  green: 1,
  lightgreen: 2,
  yellow: 3,
  red: 4,
};

function useSorting<T extends Record<string, any>>(data: T[]) {
  const [sortingOptions, setSortingOptions] = useState<SortingOptions<T>>({
    field: "", // On accepte également les sous-propriétés
    direction: "desc"
  });

  // Méthode pour accéder aux propriétés imbriquées
  const getNestedValue = (obj: any, path: string) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  const sortedData = !data || data.length === 0 ? data : [...data].sort((a, b) => {
    const direction = sortingOptions.direction === "asc" ? 1 : -1;
    const field = sortingOptions.field;

    // Accéder aux valeurs imbriquées comme `State.state`
    const valueA = typeof field === "string" ? getNestedValue(a, field) : a[field];
    const valueB = typeof field === "string" ? getNestedValue(b, field) : b[field];

    // Comparaison personnalisée pour le statut
    if (field === "State.state") {
      return (statusOrder[valueA as string] - statusOrder[valueB as string]) * direction;
    }

    // Comparaison personnalisée pour les couleurs
    if (field === "color") {
      return (colorOrder[valueA as string] - colorOrder[valueB as string]) * direction;
    }

    // Comparaison par défaut
    return valueA > valueB ? direction : -direction;
  });

  const handleSort = useCallback(
    (field: keyof T | string) => {
      setSortingOptions((options) => {
        const isSameField = options.field === field;
        const direction = isSameField
          ? options.direction === "asc"
            ? "desc"
            : "asc"
          : "asc";

        return { field, direction };
      });
    },
    []
  );

  return [sortedData, handleSort] as const;
}

export default useSorting;
