import logs from "../../assets/icons/logs.svg";
import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logos/NexumX.png";
import users from "../../assets/icons/user-search.svg";
import setting from "../../assets/icons/wrench.svg";
import logout from "../../assets/icons/logout.svg";
import useModal from "../../hooks/useModal";
import ModalDeconnexion from "../../components/Layout/ModalDeconnexion";

const NavBarSuperAdmin = () => {
  const { pathVisible, setPathVisible, setModePartner } =
    useSuperAdminPartnerStore();
  const navigate = useNavigate();
  const { modalContent, isModalOpen, openModal, closeModal } = useModal();

  return (
    <div className={`fixed left-0 h-full w-[80px] bg-nxm-blueDark rounded-r-md z-30 shadow-xl`}>
    {isModalOpen ? modalContent : null}
      <div className="flex flex-col justify-center items-center">
        <img src={logo} alt="logo nexum " className="my-10 w-12 h-12"/>
        <button
          className={`h-12 w-full flex justify-center items-center ${pathVisible === "partners" ? "bg-nxm-blueLight" : ""}`}
          onClick={() => {
            setPathVisible("partners");
            navigate("/superadmin/dashboard");
            setModePartner("create");
          }}
        >
          <img src={users} alt="" className="w-6 h-6" />
        </button>
        <button
          className={`h-12 w-full flex justify-center items-center ${pathVisible === "logs" ? "bg-nxm-blueLight" : ""}`}
          onClick={() => {
            setPathVisible("logs");
            navigate("/superadmin/dashboard");
          }}
        >
          <img src={logs} alt="" className="w-6 h-6" />
        </button>
        <button
          className={`h-12 w-full flex justify-center items-center ${pathVisible === "settings" ? "bg-nxm-blueLight" : ""}`}
          onClick={() => {
            setPathVisible("settings");
            navigate("/superadmin/dashboard");
          }}
        >
          <img src={setting} alt="" className="w-6 h-6" />
        </button>
        <button
          className={`h-12 w-full flex justify-center items-center ${pathVisible === "logout" ? "bg-nxm-blueLight" : ""}`}
          onClick={() => {
            openModal(<ModalDeconnexion closeModal={closeModal} />);
          }}
        >
          <img src={logout} alt="deconnexion" className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default NavBarSuperAdmin;
