import { CustomerDataCreate } from "../../interfaces/customer";
import { axiosWithToken, axiosWithTokenForBlob } from "./axiosService";

export const customersByIdPartner = async (idPartner: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/customers`);
    return response.data;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

export const createCustomerByIdPartner = async (data: CustomerDataCreate, idPartner: number) => {
    try {
      const response = await axiosWithToken().post(`api/partner/${idPartner}/customer`, data);
      return response.data.id;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  export const updateCustomerByIdPartner = async (data: any, idPartner: number, idCustomer: number) => {
    try {
      const response = await axiosWithToken().patch(`api/partner/${idPartner}/customer/${idCustomer}`, data);
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const getUsersCustomersByIdPartner = async (idPartner: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/users?choice=customers`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getUsersPartnerByIdPartner = async (idPartner: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/users?choice=partners`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getUserPartnerByIdCollab = async (idPartner: number) => { 
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/users/employees`);
    return response.data;
  } catch (error) {
    console.log(error);
  }


}
export const getMissionsOverviewByIdPartner = async (idPartner: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/overview`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getCategoryMissionByIdPartner = async (idPartner: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/category/mission`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getCategoriesDocRequestByIdPartner  = async (idPartner: number, categoryIdMission: number) => {
  const response = await axiosWithToken().get(`api/partner/${idPartner}/category/mission/${categoryIdMission}/category/docrequest`);
  return response.data;
}

export const createCategoryMissionByIdPartner = async (idPartner: number, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/partner/${idPartner}/category/mission`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const createCategoryDocRequestByIdPartner = async (idPartner: number, data: any) => {
  try {
    const response = await axiosWithToken().post(`api/partner/${idPartner}/category/docrequest`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteCategoryMissionByIdPartner = async (idPartner: number, idCategoryMission: number) => {
  try {
    const response = await axiosWithToken().delete(`api/partner/${idPartner}/category/mission/${idCategoryMission}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const deleteCategoryDocRequestByIdPartner = async (idPartner: number, idCategoryDocRequest: number) => {
  try {
    const response = await axiosWithToken().delete(`api/partner/${idPartner}/category/docrequest/${idCategoryDocRequest}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateCategoryMissionByIdPartner = async (idPartner: number, idCategoryMission: number, data: any) => {
  try {
    const response = await axiosWithToken().patch(`api/partner/${idPartner}/category/mission/${idCategoryMission}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const updateCategoryDocRequestByIdPartner = async (idPartner: number, idCategoryDocRequest: number, data: any) => {
  try {
    const response = await axiosWithToken().patch(`api/partner/${idPartner}/category/docrequest/${idCategoryDocRequest}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getInvoices = async (idPartner: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/admin/invoices`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getInvoicePdfByPartner = async (idPartner: number, idInvoice: number) => {
  try {
    const response = await axiosWithToken().get(`api/partner/${idPartner}/admin/invoice/${idInvoice}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const getLogo = async (idPartner: number) => {
  try {
      const response = await axiosWithToken().get(`api/partner/${idPartner}/logo`)
      return response
  } catch (error) {
      throw error
  }
}