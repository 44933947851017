import useSorting from "../../../../hooks/useSorting";
import Down from "../../../../assets/icons/chevron-down.svg";
import PaperClip from "../../../../assets/icons/paperclip.svg";
import { dateFormat } from "../../../../services/Utils/functionService";
import useTooltip from "../../../../hooks/useTooltip";
import { usePartnerRequestsStore2 } from "../../../../stores/Cac/Partner/partnerRequests";
import { usePartnerRequestDetailsStore } from "../../../../stores/Cac/Partner/partnerRequestDetails";
import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import {
  handleAllCheckboxChangeService,
  handleCheckboxChangeService,
} from "../../../../services/Api/requestService";
import SelectedUsersRequest from "../Utils/SelectedUsersRequest";
import { useEffect } from "react";
import {
  assignUserToDocRequest,
  getUserAssignToDocRequest,
} from "../../../../services/Api/customerService";
import { useParams } from "react-router-dom";
import { showToastSuccess } from "../../../../services/Toasts/toasts";
import message from "../../../../assets/icons/text-message.png";

const PartnerRequestTable = () => {
  const {
    requests,
    requestIds,
    setRequestIds,
    setRequestId,
    editStatus,
    setOpenDetails,
    displayAllRequests,
    affectedUsers,
    setAffectedUsers,
    selectedUserToAffected,
    setSelectedUserToAffected,
    docRequestUserAssign,
    setDocRequestUserAssign,
    toggleRequestAssignment,
  } = usePartnerRequestsStore2();
  const { renewable } = usePartnerMissionStore();
  const { setOpenDocuments } = usePartnerRequestDetailsStore();
  const { idCustomer, idMission } = useParams();
  // Hooks
  const [sortedData, handleSort] = useSorting(requests);
  const [tooltipState, handleMouseEnter, handleMouseLeave] = useTooltip();

  // This is for checkbox for change state of requests
  const handleCheckboxChange = (id: any): void => {
    handleCheckboxChangeService(id, requestIds, setRequestIds);
  };

  const handleAllCheckboxChange = () => {
    handleAllCheckboxChangeService(requests, requestIds, setRequestIds);
  };

  useEffect(() => {
    if (affectedUsers && selectedUserToAffected.id !== 0) {
      getUserAssignToDocRequest(
        Number(idCustomer),
        Number(idMission),
        selectedUserToAffected.id
      )
        .then((data) => {
          setDocRequestUserAssign(data);
        })
        .catch((error) => console.log(error));
    }
  }, [affectedUsers, selectedUserToAffected]);

  const handleSendAssign = () => {
    assignUserToDocRequest(
      Number(idCustomer),
      Number(idMission),
      selectedUserToAffected.id,
      docRequestUserAssign
    )
      .then(() => {
        setAffectedUsers(false);
        setDocRequestUserAssign([]);
        setSelectedUserToAffected({ id: 0, fullName: "" });
        showToastSuccess("Utilisateurs affectés avec succès");
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="w-full h-full overflow-y-scroll">
      <div className="w-full bg-nxm-red xl:text-xl py-3 border-zinc-100 grid grid-col-5 sm:grid-cols-9 sticky top-0 z-10 font-BarlowCondensed shadow-xl rounded-tl-md text-white">
        <div className="col-start-1 col-end-2 flex justify-start items-center ml-2">
          Id
          <button onClick={() => handleSort("fakeId")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="col-start-2 col-end-3 flex justify-start items-center">
          <p>Catégorie</p>
        </div>
        {!renewable ? (
          <div className="hidden col-start-3 col-end-4 lg:flex justify-start items-center">
            Création
            <button onClick={() => handleSort("createdAt")}>
              <img src={Down} alt="down" className="p-1" />
            </button>
          </div>
        ) : (
          <div className="hidden col-start-3 col-end-4 sm:flex justify-start items-center">
            Mois
          </div>
        )}
        <div className="hidden lg:flex col-start-4 col-end-5 justify-start items-center">
          Limite
          <button onClick={() => handleSort("dueDate")}>
            <img src={Down} alt="down" className="p-1" />
          </button>
        </div>
        <div className="hidden sm:inline-block sm:col-start-5 col-end-8">
          Description
        </div>
        {affectedUsers ? (
          <div className="col-start-8 col-end-10 flex justify-center items-center">
            <SelectedUsersRequest />
            <button
              onClick={handleSendAssign}
              className="flex justify-center items-center my-1 border-[1px] mr-4 rounded-sm border-filter-green w-10 ml-2 bg-filter-green hover:text-white transition-all duration-500 h-8"
            >
              OK
            </button>
            <button
              onClick={() => {
                setAffectedUsers(!affectedUsers);
                setSelectedUserToAffected({ id: 0, fullName: "" });
                setDocRequestUserAssign([]);
              }}
              className="flex justify-center items-center my-1 border-[1px] mr-4 rounded-sm border-red-500 w-10  bg-red-500 hover:text-white transition-all duration-500 h-8"
            >
              X
            </button>
          </div>
        ) : (
          <>
            <div className="hidden sm:flex sm:col-start-8 col-end-9 justify-start items-center mr-2 lg:mr-0">
              Demandeur
              <button onClick={() => handleSort("User")}>
                <img src={Down} alt="down" className="p-1" />
              </button>
            </div>
            <div className="col-start-9 col-end-10 flex justify-start items-center">
              <div className="grid grid-cols-2 w-full">
                <div className="hidden lg:col-start-1 col-end-2 mr-2 ">
                  Docs
                </div>
                <div className="col-start-1 lg:col-start-2 col-end-3 mr-2 flex">
                  <p>Statut</p>
                  {editStatus ? (
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className="ml-3 mt-1 pr-10 w-5 h-5"
                      checked={requestIds?.length === requests.length}
                      onChange={handleAllCheckboxChange}
                    />
                  ) : null}
                  <button onClick={() => handleSort("State.state")}>
                    <img src={Down} alt="down" className="p-1" />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="w-full h-[90%] font-BarlowCondensed">
        {sortedData
          ?.filter(
            (request) =>
              displayAllRequests ||
              request.isPeriodClosed === null ||
              request.isPeriodClosed === false
          )
          .map((request: any) => (
            <div
              className={`w-full m-auto border-[1px] hover:bg-nxm-lightBlue hover:bg-opacity-20 border-zinc-100 2xl:text-xl text-md font-light`}
              key={request?.id}
            >
              <button
                type="button"
                className="col-start-1 col-end-10 grid grid-cols-9 w-full py-3"
                onClick={() => {
                  setOpenDetails(true);
                  setRequestId(request?.id);
                  setOpenDocuments("Documents");
                }}
              >
                <div className="w-full col-start-1 col-end-3 sm:col-start-1 sm:col-end-2 text-start grid grid-cols-5 gap-2">
                  <div className="col-start-1 col-end-2 ml-2">
                    {request?.fakeId}
                  </div>
                  <div
                    className="col-start-2 col-end-3"
                    onMouseEnter={(e) => {
                      if (request?.refused) {
                        handleMouseEnter("Requête refusée", e);
                      }
                    }}
                    onMouseLeave={
                      request?.refused ? handleMouseLeave : undefined
                    }
                  >
                    {request?.refused === true ? "📌" : " "}
                  </div>
                  {request?.State?.id !== 1 ? (
                    <div
                      className="col-start-3 col-end-4 mr-2 flex justify-center items-center"
                      onMouseEnter={(e) => {
                        if (Object.keys(request?.reminder || {}).length > 0) {
                          handleMouseEnter(
                            `Requête relancé le ${dateFormat(
                              request.reminder?.date
                            )} par ${request?.reminder?.user}`,
                            e
                          );
                        }
                      }}
                      onMouseLeave={
                        request?.reminder ? handleMouseLeave : undefined
                      }
                    >
                      {Object.keys(request?.reminder || {}).length > 0
                        ? "📩"
                        : ""}
                    </div>
                  ) : (
                    <div className="col-start-3 col-end-4 mr-2"></div>
                  )}
                  <div className="col-start-4 col-end-5 mr-1 flex justify-center items-center">
                    {request?.chatRead === false ? (
                      <div className="rounded-full w-2 h-2 bg-red-600"></div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-start-5 col-end-6 flex justify-center items-center mr-5 mt-2">
                    {request?.commentaryCount !== 0 ? (
                      <div className="flex">
                        <img
                          src={message}
                          alt="message"
                          className="h-4 w-4 absolute"
                        />
                        <div className="rounded-full h-4 w-4 bg-white border-gray-300 border-[1px] left-4 relative -top-2 flex justify-center items-center mr-2">
                          <p className=" text-xs">{request?.commentaryCount}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-start-3 col-end-8 sm:col-start-2 sm:col-end-3 text-start text-nxm-darkGray font-bold">
                  {request?.CategoryDocRequest?.name}
                </div>
                <div className="hidden lg:inline-block lg:col-start-3 lg:col-end-4 text-start ">
                  {!renewable
                    ? dateFormat(request?.createdAt)
                    : request?.nameMissionPeriod}
                </div>
                <div className="hidden lg:inline-block lg:col-start-4 lg:col-end-5 text-start">
                  {dateFormat(request?.dueDate)}
                </div>
                <div className="hidden sm:inline-block sm:col-start-5 sm:col-end-8 text-start">
                  {request?.description}
                </div>
                {affectedUsers ? (
                  <div className="col-start-8 col-end-10 flex justify-center items-center">
                    <input
                      type="checkbox"
                      name="affected"
                      id="affected"
                      className="ml-1 w-5 h-5"
                      checked={docRequestUserAssign?.includes(request.id)}
                      onChange={(e) => {
                        e.stopPropagation();
                        toggleRequestAssignment(request.id);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div className="hidden sm:inline-block sm:col-start-8 sm:col-end-9 text-start">
                      {request?.User}
                    </div>

                    <div className="sm:col-start-9 sm:col-end-10 flex justify-center items-center">
                      <div className="w-1/2 flex justify-start items-center">
                        <button className="flex text-start">
                          <img
                            src={PaperClip}
                            alt="paperclip"
                            className="hidden lg:block pt-1"
                          />
                          <p className="pl-1 mr-2">{request?.documentCount}</p>
                        </button>
                      </div>
                      <div className="lg:w-1/2 flex justify-start items-center text-start ">
                        <div
                          className={`w-3 sm:w-6 h-3 border-[1px] rounded-md mr-6
                      ${
                        request?.State?.id === 1
                          ? `border-filter-yellow bg-filter-yellow`
                          : null
                      }
                      ${
                        request?.State?.id === 2
                          ? `border-filter-blue bg-filter-blue`
                          : null
                      }
                      ${
                        request?.State?.id === 3
                          ? `border-filter-green bg-filter-green`
                          : null
                      }
                      ${
                        request?.State?.id === 4
                          ? `border-filter-orange bg-filter-orange`
                          : null
                      }
                      `}
                        ></div>
                        {editStatus ? (
                          <>
                            <input
                              type="checkbox"
                              name={request?.id}
                              className="ml-1 w-5 h-5"
                              checked={requestIds?.includes(request.id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                handleCheckboxChange(request?.id);
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </button>
            </div>
          ))}
      </div>
      {tooltipState.content && (
        <div
          className="bg-gray-600 text-white p-2 rounded-md text-sm absolute z-50 w-auto max-w-[200px] h-auto"
          style={{
            top: tooltipState.position.y,
            left: tooltipState.position.x,
          }}
        >
          {tooltipState.content}
        </div>
      )}
    </div>
  );
};

export default PartnerRequestTable;
