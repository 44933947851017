import { Route, Routes, useLocation } from "react-router-dom";
import Connexion from "../pages/Layout/Connexion";
import Error from "../pages/Layout/Error";
import ResetPassword from "../pages/Layout/ResetPassword";
import CustomerRequests from "../pages/Cac/Customer/CustomerRequests";
import PartnerHomeCustomer from "../pages/Cac/Partner/PartnerHomeCustomer";
import PartnerMissions from "../pages/Cac/Partner/PartnerMissions";
import PartnerRequests from "../pages/Cac/Partner/PartnerRequests";
import RecoveryFile from "../pages/Cac/Partner/RecoveryFile";
import PartnerMonitoring from "../pages/Cac/Partner/PartnerMonitoring";
import RedirectToError from "../components/Layout/RedirectToError";
import AdminPartner from "../pages/Admin/AdminPartner";
import AdminCustomer from "../pages/Admin/AdminCustomer";
import AdminClientFile from "../pages/Admin/AdminClientFile";
import AdminMission from "../pages/Admin/AdminMission";
import PartnerMonitoringMission from "../pages/Cac/Partner/PartnerMonitoringMission";
import SuperAdminHome from "../pages/SuperAdmin/SuperAdminHome";
import AdminCategories from "../pages/Admin/AdminCategories";
import CGU from "../pages/Informations/CGU";
import LegalNotice from "../pages/Informations/LegalNotice";
import CookiesNotice from "../pages/Informations/CookiesNotice";
import CustomerHome from "../pages/Cac/Customer/CustomerHome";
import CustomerMonitoring from "../pages/Cac/Customer/CustomerMonitoring";
import ResetPasswordFirstCnnection from "../pages/Layout/ResetPasswordFirstConnection";
import Dashboard from "../pages/Layout/Dashboard";
import { useTokenContext } from "../context/tokenContext";
import { useEffect } from "react";
import { useServiceStore } from "../stores/admin/service";
import { usePartnerRequestsStore2 } from "../stores/Cac/Partner/partnerRequests";
import CustomerWorkingFolder from "../pages/Cac/Customer/CustomerWorkingFolder";
import PartnerWorkingFolder from "../pages/Cac/Partner/PartnerWorkingFolder";
import { usePartnerMissionStore } from "../stores/Cac/Partner/partnerMission";
import { useRecoveryFileStore } from "../stores/Utils/recoveryFIle";
import AdminInvoices from "../pages/Admin/AdminInvoices";

const AppRoutes = () => {
  const { isSuperAdmin, utc, utp, isAdmin, setRoleId, token } =
    useTokenContext();
  const location = useLocation();
  const { setSearchTerm } = useServiceStore();
  const { setOpenDetails, setRequestId, setRequests } =
    usePartnerRequestsStore2();
  const { setMissions } = usePartnerMissionStore();
  const { resetSelect } = useRecoveryFileStore();

  useEffect(() => {
    if (location.pathname === "/dashboard") {
      setRoleId(0);
      localStorage.removeItem("role");
    }
    setSearchTerm("");

    if (!location.pathname.includes("requests")) {
      setRequestId(0);
      setOpenDetails(false);
      setRequests([]);
    }

    setMissions([]);
    resetSelect();
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Connexion />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<RedirectToError />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        <Route
          path="/resetpassword/firstco"
          element={<ResetPasswordFirstCnnection />}
        />
        <Route path="/legalnotice" element={<LegalNotice />} />
        <Route path="/cookiesnotice" element={<CookiesNotice />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/workingfolder" element={<CustomerWorkingFolder />} />

        <Route
          path="/partner/:idPartner/customers/:idCustomer/workingfolder/:idWorkingFolder"
          element={<PartnerWorkingFolder />}
        />
        {token === 1 && <Route path="/dashboard" element={<Dashboard />} />}

        {isSuperAdmin === 1 && (
          // Routes for superadmin
          <Route path="/superadmin/dashboard" element={<SuperAdminHome />} />
        )}

        {isAdmin === 1 && (
          // Routes for admin
          <>
            <Route
              path="/partner/:idPartner/admin/users/partner"
              element={<AdminPartner />}
            />
            <Route
              path="/partner/:idPartner/admin/users/customer"
              element={<AdminCustomer />}
            />
            <Route
              path="/partner/:idPartner/admin/clientfile"
              element={<AdminClientFile />}
            />
            <Route
              path="/partner/:idPartner/admin/category"
              element={<AdminCategories />}
            />
            <Route
              path="/partner/:idPartner/admin/invoice"
              element={<AdminInvoices />}
            />
            <Route
              path="/partner/:idPartner/admin/mission"
              element={<AdminMission />}
            />
            <Route
              path="/partner/:idPartner/customers/:idCustomer/missions/:idMission/recoveryfile"
              element={<RecoveryFile />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
          </>
        )}
        {utp === 1 && (
          // Routes for partner
          <>
            <Route
              path="/partner/:idPartner/customers"
              element={<PartnerHomeCustomer />}
            />
            <Route
              path="/partner/:idPartner/monitoring/missions"
              element={<PartnerMonitoringMission />}
            />
            <Route
              path="/partner/:idPartner/customers/:idCustomer/missions"
              element={<PartnerMissions />}
            />
            <Route
              path="/partner/:idPartner/customers/:idCustomer/missions/:idMission/requests"
              element={<PartnerRequests />}
            />
            <Route
              path="/partner/:idPartner/customers/:idCustomer/missions/:idMission/monitoring"
              element={<PartnerMonitoring />}
            />
            <Route
              path="/partner/:idPartner/customers/:idCustomer/missions/:idMission/recoveryfile"
              element={<RecoveryFile />}
            />
            <Route path="/dashboard" element={<Dashboard />} />
          </>
        )}
        {utc === 1 && (
          // Routes for customer
          <>
            <Route
              path="/customer/:idCustomer/missions"
              element={<CustomerHome />}
            />
            <Route
              path="/customer/:idCustomer/missions/:idMission/requests"
              element={<CustomerRequests />}
            />
            <Route
              path="/customer/:idCustomer/missions/:idMission/recoveryfile"
              element={<CustomerMonitoring />}
            />
          </>
        )}
      </Routes>
    </>
  );
};

export default AppRoutes;
