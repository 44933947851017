import { usePartnerMissionStore } from "../../../../stores/Cac/Partner/partnerMission";
import "../../../../css/animations.css";

const UploadMission = () => {
  const { dataMission, setDataMission, checkboxRelaunchAuto, setCheckboxRelaunchAuto } = usePartnerMissionStore();

  const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setDataMission({ ...dataMission, file: file });
    }
  };

  return (
    <div className="w-10/12 m-auto flex justify-center items-center">
      <div className="w-1/2 flex flex-col justify-start items-start mb-4">
        <p>Télécharger la lettre de mission : </p>
        <input
          type="file"
          name="doc"
          id="fileUpload"
          onChange={handleUpload}
          className="w-full py-1 m-auto"
          />
      </div>
      {/* This is for create a new mission */}
      <div className="1/2 flex flex-col justify-start items-start mb-4">
         <p>Relance des requêtes en retard automatique : </p>
         <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input 
              type="checkbox" 
              name="toggle" 
              id="toggle" 
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
              checked={checkboxRelaunchAuto}
              onChange={() => {
                setCheckboxRelaunchAuto(!checkboxRelaunchAuto)
              }}
            />
            <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
         </div>
      </div>
    </div> 
  );
};

export default UploadMission;
