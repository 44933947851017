import { useNavigate, useParams } from "react-router-dom";
import { useCustomerRequestsStore } from "../../../stores/Cac/Customer/customerRequests";
import { useTokenContext } from "../../../context/tokenContext";
import { useEffect } from "react";
import { useRecoveryFileStore } from "../../../stores/Utils/recoveryFIle";
import {
  convertData,
  cutName,
  dateFormat,
  splitMissionName,
} from "../../../services/Utils/functionService";
import RequestDocuments from "../../../components/Cac/Customer/Request/RequestDocuments";
import Camembert from "../../../components/Cac/Partner/Utils/MonitoringElements/Camembert";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import Return from "../../../assets/icons/return.svg";
import {
  getDocRequests,
  getMissionDataByCustomer,
  getPercentageMission,
} from "../../../services/Api/customerService";
import { showToastError } from "../../../services/Toasts/toasts";
import SideBar from "../../../components/Layout/SideBar";

const CustomerMonitoring = () => {
  const navigate = useNavigate();
  const { idCustomer, idMission } = useParams();
  const { utc } = useTokenContext();
  const {
    openDetailsCustomer,
    setOpenDetailsCustomer,
    requestIdCustomer,
    setRequestIdCustomer,
  } = useCustomerRequestsStore();
  const {
    percent,
    setPercent,
    customerName,
    setCustomerName,
    missionName,
    setMissionName,
    missionInProgress,
    setMissionInProgress,
    missionDeadLine,
    setMissionDeadLine,
  } = useRecoveryFileStore();

  useEffect(() => {
    getPercentageMission(Number(idCustomer), Number(idMission))
      .then((data) => {
        const convertedData = convertData(data.percentage);
        setPercent(convertedData);
      })
      .catch((error) => console.log(error));
    getMissionDataByCustomer(Number(idCustomer), Number(idMission))
      .then((data) => {
        setMissionName(data.name);
        const customerNameCut = cutName(data.name);
        setCustomerName(customerNameCut);
      })
      .catch((error) => console.log(error));
    getDocRequests(Number(idCustomer), Number(idMission))
      .then((data) => {
        const filteredDataDeadline = data.filter(
          (item: any) => item.State.id === 4
        );
        setMissionDeadLine(filteredDataDeadline);
        const filteredDataInProgress = data.filter(
          (item: any) => item.State.id === 1
        );
        setMissionInProgress(filteredDataInProgress);
      })
      .catch((error) => console.log(error));
  }, [openDetailsCustomer, requestIdCustomer]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex">
      <RequestDocuments />
      <SideBar />
      <div className="flex flex-col justify-start items-center w-full mt-10">
        <div className="h-[90%] w-full grid grid-cols-5 grid-rows-4">
          <div className="grid col-start-1 col-end-3 row-start-1 row-end-2 w-full m-auto ">
            <div className="flex flex-col pl-10 font-K2D">
              <h2 className="text-nxm-darkGray font-light 3xl:text-4xl text-2xl pt-2 flex">
                Client :{" "}
                <span className="flex font-bold pl-2">{customerName}</span>
              </h2>
              <p className="font-light pt-2 flex text-nxm-darkGray">
                Récapitulatif des états de requêtes pour la mission :
                <span className="flex font-bold pl-2">
                  {splitMissionName(missionName)}
                </span>
              </p>
              <button
                type="button"
                className="flex mt-8"
                onClick={() => navigate(-1)}
              >
                <img src={Return} alt="return" />
              </button>
            </div>
          </div>
          <div className="ml-32 mt-10 col-start-1 col-end-3 row-start-2 row-end-5">
            <Camembert data={percent} />
            <StatusRequest />
          </div>

          <div className="col-start-3 col-end-6 w-11/12 row-start-1 row-end-5 m-auto h-[95%] font-BarlowCondensed text-lg text-nxm-darkGray">
            <p className="font-light w-11/12 pb-2">
              Liste des requêtes hors délais :
            </p>
            <div className="w-11/12 border-2 border-zinc-100 rounded-md bg-white h-[40%]">
              <div className="bg-zinc-300 border-2 p-3 border-zinc-100 grid grid-cols-3">
                <div className="hidden sm:flex sm:col-start-1 sm:col-end-2 text-start">
                  # de requêtes
                </div>
                <div className="hidden sm:flex sm:col-start-2 sm:col-end-3 text-start">
                  Catégorie
                </div>
                <div className="hidden sm:flex sm:col-start-3 sm:col-end-4 text-start">
                  Jours de retard
                </div>
              </div>
              <div className="w-full h-[90%] overflow-y-scroll">
                {missionDeadLine?.map((mission: any) => (
                  <div
                    key={mission?.id}
                    className={`grid grid-cols-3 items-center p-3 border-b-2 border-zinc-100  ${
                      mission?.isAssigned === true
                        ? "bg-white"
                        : "bg-gray-100 text-gray-400"
                    }`}
                  >
                    <button
                      type="button"
                      className={`grid grid-cols-3 col-start-1 col-end-9 w-full py-3 cursor-pointer ${
                        mission?.isAssigned === true
                          ? "cursor-pointer"
                          : "cursor-not-allowed"
                      }`}
                      onClick={() => {
                        if (utc === 1 && mission?.isAssigned === true) {
                          setOpenDetailsCustomer(true);
                          setRequestIdCustomer(mission?.id);
                        } else if (utc === 1 && mission?.isAssigned === false) {
                          setOpenDetailsCustomer(false);
                          showToastError(
                            "Une erreur est survenue. Veuillez réessayer."
                          );
                        } else {
                          return;
                        }
                      }}
                    >
                      <div className="hidden sm:flex sm:col-start-1 sm:col-end-2 ">
                        {mission?.fakeId}
                      </div>
                      <div className="hidden sm:flex sm:col-start-2 sm:col-end-3 ">
                        {mission?.CategoryDocRequest.name}
                      </div>
                      <div className="hidden sm:flex sm:col-start-3 sm:col-end-4 ">
                        {mission?.overdue}
                      </div>
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-start-3 col-end-6 row-start-3 row-end-5 pt-8 h-[120%]">
              <p className="font-light w-11/12 pb-2">
                Liste des requêtes en attente :
              </p>
              <div className="w-11/12 border-2 border-zinc-100 h-[40%] rounded-md bg-white">
                <div className=" bg-zinc-300 border-2 p-3 border-zinc-100 grid grid-cols-3">
                  <div className="hidden sm:flex sm:col-start-1 sm:col-end-2 ">
                    # de requêtes
                  </div>
                  <div className="hidden sm:flex sm:col-start-2 sm:col-end-3 ">
                    Catégorie
                  </div>
                  <div className="hidden sm:flex sm:col-start-3 sm:col-end-4 ">
                    Date limite
                  </div>
                </div>
                <div className="w-full h-[90%] overflow-y-scroll">
                  {missionInProgress?.map((mission: any) => (
                    <>
                      <div
                        key={mission?.id}
                        className={`grid grid-cols-3 items-center p-3 border-b-2 border-zinc-100 ${
                          mission?.isAssigned === true
                            ? "bg-white"
                            : "bg-gray-200 text-gray-400"
                        }`}
                      >
                        <button
                          type="button"
                          className={`grid grid-cols-3 col-start-1 col-end-9 w-full py-3 ${
                            mission?.isAssigned === true
                              ? "cursor-pointer"
                              : "cursor-not-allowed"
                          }`}
                          onClick={() => {
                            if (utc === 1 && mission?.isAssigned === true) {
                              setOpenDetailsCustomer(true);
                              setRequestIdCustomer(mission?.id);
                            } else if (
                              utc === 1 &&
                              mission?.isAssigned === false
                            ) {
                              setOpenDetailsCustomer(false);
                            } else {
                              return;
                            }
                          }}
                        >
                          <div className="hidden sm:flex sm:col-start-1 sm:col-end-2 ">
                            {mission?.fakeId}
                          </div>
                          <div className="hidden sm:flex sm:col-start-2 sm:col-end-3 ">
                            {mission?.CategoryDocRequest.name}
                          </div>
                          <div className="hidden sm:flex sm:col-start-3 sm:col-end-4 ">
                            {dateFormat(mission?.dueDate)}
                          </div>
                        </button>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerMonitoring;
