import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  dateFormat,
  splitMissionName,
} from "../../../services/Utils/functionService";
import ProgressBar from "../../../components/Cac/Partner/Utils/MonitoringElements/ProgressBar";
import StatusRequest from "../../../components/Cac/Customer/Request/StatusRequest";
import CustomerRequestTable from "../../../components/Cac/Customer/Request/CustomerRequestTable";
import Return from "../../../assets/icons/return.svg";
import { useCustomerRequestsStore } from "../../../stores/Cac/Customer/customerRequests";
import { usePartnerRequestDetailsStore } from "../../../stores/Cac/Partner/partnerRequestDetails";
import RequestDocuments from "../../../components/Cac/Customer/Request/RequestDocuments";
import { usePartnerRequestsStore2 } from "../../../stores/Cac/Partner/partnerRequests";
import {
  getDocRequests,
  getMissionDataByCustomer,
} from "../../../services/Api/customerService";
import { ToastContainer } from "react-toastify";
import { useCustomerMissionStore } from "../../../stores/Cac/Customer/customerMission";
import SideBar from "../../../components/Layout/SideBar";

const CustomerRequests = () => {
  // Store
  const {
    dataMission,
    setDataMission,
    requests,
    setRequests,
    openDetailsCustomer,
    setRequestIdCustomer,
    displayAllRequests,
    setDisplayAllRequests,
  } = useCustomerRequestsStore();
  const { requestRefused } = usePartnerRequestDetailsStore();
  const { updateRequest } = usePartnerRequestsStore2();
  const { isRenewable } = useCustomerMissionStore();

  // Hooks
  const navigate = useNavigate();
  const { idCustomer, idMission } = useParams();

  useEffect(() => {
    if (openDetailsCustomer === undefined) setRequestIdCustomer(0);
  }, [openDetailsCustomer]);

  useEffect(() => {
    getMissionDataByCustomer(Number(idCustomer), Number(idMission))
      .then((data) => setDataMission(data))
      .catch((error) => console.log(error));
    if (idCustomer && idMission) {
      getDocRequests(parseInt(idCustomer), parseInt(idMission))
        .then((data) => setRequests(data))
        .catch((error) => console.log(error));
    }
  }, [openDetailsCustomer, requestRefused, updateRequest]);

  return (
    <div className="w-screen h-screen bg-background bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overflow-hidden flex">
      <SideBar />
      <RequestDocuments />
      <ToastContainer position="top-center" />
      <div className="flex flex-col justify-start items-center w-10/12 h-screen">
        <div className="w-11/12 flex flex-col justify-start items-start mt-5">
          <h2 className="text-nxm-darkGray font-light 2xl:text-3xl text-xl font-K2D ">
            Mission : {splitMissionName(dataMission?.name)}
          </h2>
          <div className="flex justify-between items-center w-full mt-2 font-BarlowCondensed text-lg">
            <p className="font-thin italic text-sm md:text-base">
              Date de fin de mission : {dateFormat(dataMission?.dueDate)}
            </p>
            <StatusRequest />
          </div>
          <div className="flex justify-between items-center w-full">
            <button
              type="button"
              className="flex py-2 mr-4"
              title="Retour"
              onClick={() => navigate(-1)}
            >
              <img src={Return} alt="return" />
            </button>
            {requests.length > 0 ? (
              <div className="pt-2 flex flex-col sm:flex-row sm:items-center sm:justify-center items-start justify-start mr-24  font-BarlowCondensed">
                <ProgressBar />
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="flex justify-start items-center w-full mb-2 mt-2 font-BarlowCondensed">
            <button
              type="button"
              onClick={() =>
                navigate(
                  `/customer/${idCustomer}/missions/${idMission}/recoveryfile`
                )
              }
              className="hidden sm:w-42 sm:h-8 mr-2 text-sm sm:flex items-center justify-center border-[1px] border-gray-300 rounded-sm p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white"
            >
              Rapport d'état des requêtes
            </button>
            {isRenewable && (
              <button
                type="button"
                className="hidden sm:w-52 sm:h-8 ml-2 text-md sm:flex items-center justify-center rounded-sm border-[1px] border-gray-300 p-2 hover:bg-nxm-red hover:bg-opacity-70 hover:text-white transition-all duration-500 bg-white"
                onClick={() => setDisplayAllRequests(!displayAllRequests)}
              >
                <p>Afficher les anciennes requêtes</p>
              </button>
            )}
          </div>
        </div>
        <div className="w-11/12 border-2 border-zinc-100 h-[90%] 2xl:h-[75%] rounded-md bg-white mb-20">
          <CustomerRequestTable />
        </div>
      </div>
    </div>
  );
};

export default CustomerRequests;
