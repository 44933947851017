import { create } from "zustand";
import { ICreateMission, IMission, IMissionDetails } from "../../../interfaces/mission";
import { User } from "../../../interfaces/user";

interface PartnerMissionState {
    missions: IMission[];
    setMissions: (missions: IMission[]) => void;

    choiceModal: string;
    setChoiceModal: (choiceModal: string) => void;

    missionId: number;
    setMissionId: (missionId: number) => void;

    usersClients: any[];
    setUsersClients: (usersClients: any[]) => void;

    collabsAssignedToClient: any[];
    setCollabsAssignedToClient: (collabsAssignedToClient: any[]) => void;

    checkboxValuesCollabs: { [key: number]: boolean };
    setCheckboxValuesCollabs: (checkboxValuesCollabs: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;

    initialCheckboxValuesCollabs: { [key: number]: boolean };
    setInitialCheckboxValuesCollabs: (initialCheckboxValuesCollabs: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;
    
    checkboxValuesClients: { [key: number]: boolean };
    setCheckboxValuesClients: (setCheckboxValuesClients: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;

    initialCheckboxValuesClients: { [key: number]: boolean };
    setInitialCheckboxValuesClients: (initialCheckboxValuesClients: ((prevState: { [key: number]: boolean }) => { [key: number]: boolean }) | { [key: number]: boolean }) => void;

    createNewClient: boolean;
    setCreateNewClient: (createNewClient: boolean) => void;

    dataMission: ICreateMission;
    setDataMission: (dataMission: ICreateMission) => void;

    newUser : User;
    setNewUser: (newUser: User) => void;

    resetNewUser: () => void;
    
    resetMission: () => void;

    errorLimitMission: boolean;
    setErrorLimitMission: (errorLimitMission: boolean) => void;

    renewable: boolean;
    setRenewable: (renewable: boolean) => void;

    updateMission: boolean;
    setUpdateMission: (updateMission: boolean) => void;

    checkboxRelaunchAuto: boolean;
    setCheckboxRelaunchAuto: (checkboxRelaunchAuto: boolean) => void;

    // UPDATE MISSION
    missionDetails: IMissionDetails;
    setMissionDetails: (missionDetails: IMissionDetails) => void;

}

export const usePartnerMissionStore = create<PartnerMissionState>((set) => ({
    missions: [],
    setMissions: (missions) => set({ missions: missions }),

    errorLimitMission: false,
    setErrorLimitMission: (errorLimitMission) => set({ errorLimitMission: errorLimitMission }),

    choiceModal: "",
    setChoiceModal: (choiceModal) => set({ choiceModal: choiceModal }),

    usersClients: [],
    setUsersClients: (usersClients) => set({ usersClients: usersClients }),

    collabsAssignedToClient: [],
    setCollabsAssignedToClient: (collabsAssignedToClient) =>
        set({ collabsAssignedToClient: collabsAssignedToClient }),
    
    checkboxValuesCollabs: {},
    setCheckboxValuesCollabs: (checkboxValuesCollabs) => set((prevState) => {
        if (typeof checkboxValuesCollabs === 'function') {
            return { checkboxValuesCollabs: checkboxValuesCollabs(prevState.checkboxValuesCollabs) };
        }
        return { checkboxValuesCollabs };
        }),

    initialCheckboxValuesCollabs: {},
    setInitialCheckboxValuesCollabs: (initialCheckboxValuesCollabs) => set((prevState) => {
        if (typeof initialCheckboxValuesCollabs === 'function') {
            return { initialCheckboxValuesCollabs: initialCheckboxValuesCollabs(prevState.initialCheckboxValuesCollabs) };
        }
        return { initialCheckboxValuesCollabs };
    }),

    checkboxValuesClients: {},
    setCheckboxValuesClients: (checkboxValuesClients) => set((prevState) => {
        if (typeof checkboxValuesClients === 'function') {
            return { checkboxValuesClients: checkboxValuesClients(prevState.checkboxValuesClients) };
        }
        return { checkboxValuesClients };
    }),

    initialCheckboxValuesClients: {},
    setInitialCheckboxValuesClients: (initialCheckboxValuesClients) => set((prevState) => {
        if (typeof initialCheckboxValuesClients === 'function') {
            return { initialCheckboxValuesClients: initialCheckboxValuesClients(prevState.initialCheckboxValuesClients) };
        }
        return { initialCheckboxValuesClients };
    }),
    
    missionId: 0,
    setMissionId: (missionId) => set({ missionId: missionId }),

    // Create New Client
    createNewClient: false,
    setCreateNewClient: (createNewClient) => set({ createNewClient: createNewClient }),

    dataMission: {
        idCustomer: 0,
        dateExercice: 0,
        file: null,
        categoryId: 0,
        managerId: 0,
        dueDate: null,
        partnerUsers: [],
        existingCustomerUsers: [],
        startMonth: "",
        periodType: 0,
        autoRemind: false,
    },
    setDataMission: (dataMission) => set({ dataMission: dataMission }),    

    checkboxRelaunchAuto: false,
    setCheckboxRelaunchAuto: (checkboxRelaunchAuto) => set({ checkboxRelaunchAuto: checkboxRelaunchAuto }),

    newUser: {
        firstName: "",
        lastName: "",
        eMail: "",
        idRole: 2,
        titre: "",
    },
    setNewUser: (newUser) => set({ newUser: newUser }),

    resetNewUser: () => set({ 
        newUser: {
            firstName: "",
            lastName: "",
            eMail: "",
            idRole: 2,
            titre: "",
        }
    }),

    renewable: false,
    setRenewable: (renewable) => set({ renewable: renewable }),

    resetMission: () => set({ 
        newUser: {
            firstName: "",
            lastName: "",
            eMail: "",
            idRole: 2,
            titre: "",
        },
        checkboxRelaunchAuto: false,
        checkboxValuesCollabs: {},
        collabsAssignedToClient: [],
        usersClients: [],
        dataMission: {
            idCustomer: 0,
            dateExercice: 0,
            categoryId: 0,
            file: null,
            managerId: 0,
            dueDate: null,
            partnerUsers: [],
            existingCustomerUsers: [],
            startMonth: "",
            periodType: 0,
            autoRemind: false,
        }
    }),

    updateMission: false,
    setUpdateMission: (updateMission) => set({ updateMission: updateMission }),

    // UPDATE MISSION
    missionDetails: {
        id: 0,
        dateExercice: "",
        idCustomer: 0,
        categoryId: 0,
        isThereALetter: false,
        name: "",
        closed: 0,
        dueDate: "",
        createdAt: "",
        updatedAt: "",
        manager: "",
        isRenewable: false,
    },
    setMissionDetails: (missionDetails) => set({ missionDetails: missionDetails }),
}));

