import { useEffect } from "react";
import ModalForgotPassword from "../../components/Layout/ModalForgotPassword";
import { useTokenContext } from "../../context/tokenContext";
import { NavLink, useNavigate } from "react-router-dom";
import { login } from "../../services/Api/loginService";
import Cookies from "js-cookie";
import Eye from "../../assets/icons/eye.svg";
import { redirection } from "../../services/Utils/functionService";
import Modal2fa from "../../components/Layout/Modal2fa";
import { useConnexionStore } from "../../stores/Layout/connexion";
import { initSocket } from "../../services/Utils/socketIo";
import { getNotifications } from "../../services/Api/userService";
import { useNotifsStore } from "../../stores/Utils/notifs";
import { useMfaStore } from "../../stores/Utils/mfa";
import ModalMfa from "../../components/Layout/ModalMfa";
import logo from "../../assets/logos/logoNexum.png";
import { ToastContainer } from "react-toastify";

const Connexion = () => {
  // Store
  const {
    setDisplayPopUpCookie,
    email,
    setEmail,
    password,
    setPassword,
    setShowModalForgotPassword,
    passwordInputType,
    setPasswordInputType,
    setShowModal2fa,
  } = useConnexionStore();
  const { setNotifs } = useNotifsStore();
  const { setShowModalCodeMFA } = useMfaStore();
  // Hooks
  const navigate = useNavigate();

  // Cookie
  const consentementCookies = Cookies.get("consentementCookies");

  // Context
  const {
    setToken,
    setFirstname,
    setLastname,
    setEmailUser,
    setUserId,
    setMfa,
    setUtc,
    setUtp,
    setIsAdmin,
    setIsSuperAdmin,
  } = useTokenContext();

  const togglePasswordVisibility = () => {
    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  useEffect(() => {
    if (!consentementCookies) {
      setDisplayPopUpCookie(true);
    } else {
      setDisplayPopUpCookie(false);
    }
  }, [consentementCookies]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  // function for login
  const handleLogin = async () => {
    try {
      const response = await login(email, password);
      setFirstname(response?.data?.firstName);
      localStorage.setItem("firstName", response?.data?.firstName);
      setLastname(response?.data?.lastname);
      localStorage.setItem("lastName", response?.data?.lastName);
      setEmailUser(response?.data?.eMail);
      setUserId(response?.data?.id);
      setMfa(response?.data?.mfa);
      localStorage.setItem("mfa", response?.data?.mfa);
      setUtc(response?.data?.utc);
      localStorage.setItem("utc", response?.data?.utc);
      setUtp(response?.data?.utp);
      localStorage.setItem("utp", response?.data?.utp);
      setIsSuperAdmin(response?.data?.isSuperAdmin);
      localStorage.setItem("isSuperAdmin", response?.data?.isSuperAdmin);
      setIsAdmin(response?.data?.isAdmin);
      localStorage.setItem("isAdmin", response?.data?.isAdmin);
      setToken(1);
      if (response?.data?.lastConnection === null) {
        localStorage.setItem("lastConnection", "null");
      }
      // Socket
      if (response?.data?.id) {
        initSocket(response?.data?.id);
        setEmail("");
        setPassword("");
        // Get notifications
        getNotifications()
          .then((response) => setNotifs(response))
          .catch((error) => console.log(error));
      }

      redirection(location, navigate, response?.data?.isSuperAdmin);
    } catch (error: any) {
      if (error.message) {
        setUtc(0);
        setUtp(0);
        setIsAdmin(0);
        setIsSuperAdmin(0);
        setFirstname("");
        localStorage.clear();
      }
      if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "eMail 2FA sent"
      ) {
        setShowModal2fa(true);
      } else if (
        error.message &&
        typeof error.message === "string" &&
        error.message.trim() === "2faTotpRequired"
      ) {
        setShowModalCodeMFA(true);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <div className="w-screen h-screen flex bg-Monaco bg-no-repeat bg-cover bg-center bg-fixed bg-opacity-50 backdrop-filter backdrop-blur-lg overscroll-y-none ">
      <ToastContainer position="top-center" />
      <div className="hidden md:flex flex-col justify-center items-center h-screen w-8/12">
        <div className="absolute top-5 left-14">
          <img
            src={logo}
            alt="logo Nexum"
            className="3xl:w-36 3xl:h-36 w-28 h-28"
          />
        </div>
      </div>

      <div className="w-full h-screen flex items-center justify-around flex-col md:w-4/12 3xl:w-3/12 bg-white md:mr-28">
        <div className="flex flex-col justify-around items-center h-auto 3xl:h-1/3">
          <h1 className="font-K2D 3xl:text-3xl text-2xl font-bold text-nxm-red 2xl:my-8 my-4">
            BIENVENUE SUR NEXUM
          </h1>
          <h2 className="hidden xl:flex xl:w-8/12 font-BarlowCondensed text-nxm-red text-sm 2xl:text-lg italic mb-2 font-thin ">
            Notre plateforme a été développée avec un objectif principal: <br />
            assurer les échanges sécurisés de documents dans le cadre de vos
            missions et pour vous offrir une expérience utilisateur optimale.
          </h2>
        </div>
        <div className="flex flex-col items-center justify-around w-8/12 h-1/2 3xl:h-2/3 border-2 rounded-md border-opacity-20 border-nxm-red shadow-lg">
          <h2 className="text-nxm-darkBlue font-bold 3xl:text-3xl text-xl font-K2D 3xl:mt-2 my-2 ">
            CONNEXION
          </h2>
          <div className="flex justify-start w-8/12">
            <label
              htmlFor="email"
              className="text-nxm-darkBlue text-md 3xl:text-xl font-BarlowCondensed"
            >
              Identifiant :
            </label>
          </div>
          <input
            type="email"
            className="border-b-[1px] border-nxm-darkBlue w-8/12 h-8 3xl:h-10 bg-white focus:outline-none"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            onChange={(e) => setEmail(e.target.value)}
            minLength={10}
            maxLength={200}
            value={email}
            name="email"
            id="email"
            placeholder="Email"
            required
          />
          <div className="flex justify-start w-8/12 my-2 xl:my-0">
            <label
              htmlFor="password"
              className="text-nxm-darkBlue text-md 3xl:text-xl font-BarlowCondensed"
            >
              Mot de passe :
            </label>
          </div>
          <div className="relative flex w-8/12 h-10 ">
            <input
              type={passwordInputType}
              className="border-b-[1px] border-nxm-darkBlue bg-white w-full focus:outline-none h-8 3xl:h-10"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              value={password}
              id="password"
              placeholder="********"
              onKeyDown={handleKeyDown}
              required
            />
            <button
              className="absolute right-2 top-2"
              onClick={togglePasswordVisibility}
            >
              <img src={Eye} alt="oeil" />
            </button>
          </div>
          <button
            type="button"
            className="underline text-nxm-darkBlue text-xs sm:text-base font-BarlowCondensed my-2 xl:my-0"
            onClick={() => setShowModalForgotPassword(true)}
          >
            Mot de passe oublié ?
          </button>
          <ModalForgotPassword />

          <button
            type="button"
            onClick={handleLogin}
            tabIndex={0}
            onFocus={(event) => (event.target.style.outline = "blue")}
            className="font-BarlowCondensed text-xl w-28 border-2 border-nxm-darkBlue sm:w-32 sm:mx-10 rounded-md 2xl:h-10 h-8 bg-nxm-lightBlue text-white hover:shadow-lg hover:bg-white hover:text-nxm-darkBlue transition-all duration-500 my-2 lg:my-0"
          >
            Connexion
          </button>

          <Modal2fa />
          <ModalMfa />
        </div>
        <div className="font-BarlowCondensed 3xl:h-1/3 flex flex-col justify-end items-center">
          <div className="flex justify-center items-center">
            <NavLink
              to="/legalnotice"
              className="underline text-sm text-nxm-darkBlue"
            >
              Mentions légales
            </NavLink>
            {" - "}
            <NavLink
              to="/cookiesnotice"
              className="underline text-sm text-nxm-darkBlue"
            >
              Cookies
            </NavLink>
            {" - "}
            <NavLink to="/cgu" className="underline text-sm text-nxm-darkBlue">
              CGU
            </NavLink>
          </div>
          <p className="font-extralight text-sm text-center pt-2 pb-4 text-nxm-darkBlue">
            © 2024 by TRIONODE.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Connexion;
