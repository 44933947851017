import { create } from "zustand";

type InvoiceState = {
    invoices: any[];
    setInvoices: (invoices: any[]) => void;
}

export const useInvoiceStore = create<InvoiceState>((set) => ({
    invoices: [],
    setInvoices: (invoices) => set({ invoices }),
}));