import { useSuperAdminPartnerStore } from "../../stores/superadmin/superadminpartner";
import pen from "../../assets/icons/edit.svg";
import trash from "../../assets/icons/trash.svg";
import clip from "../../assets/icons/paperclip.svg";
import plus from "../../assets/icons/plus.svg";
import { getMandate } from "../../services/Api/partnerSuscriptionService";
import "../../css/animations.css";
import DeletePartnerSuperAdmin from "./modal/DeletePartnerSuperAdmin";
import { useSuperAdminCreatePartnerStore } from "../../stores/superadmin/createPartner";
import CreatePartner from "./modal/CreatePartner";
import { dateFormat } from "../../services/Utils/functionService";
import { useNavigate } from "react-router-dom";
import AddUser from "./modal/AddUser";
import {
  downloadPdfInvoice,
  getInvoices,
} from "../../services/Api/superadminService";
import { useState } from "react";
import pdf from "../../assets/icons/pdf.png";
import image from "../../assets/icons/image-plus.svg";
import addUserIcon from "../../assets/icons/user-plus.svg";
import AddLogo from "./modal/AddLogo";
const SuperAdminPartnerTable = ({ openModal, closeModal }: any) => {
  // Store
  const { partners, setPartnerId, setModePartner, invoices, setInvoices } =
    useSuperAdminPartnerStore();
  const { openDetails, setOpenDetails, displayDiv, setDisplayDiv } =
    useSuperAdminCreatePartnerStore();

  // Hook
  const navigate = useNavigate();

  const downloadMandate = (idPartnerSubscription: number) => {
    getMandate(idPartnerSubscription)
      .then((response) => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "mandat.pdf");
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        } else {
          console.error("La réponse est undefined");
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInvoiceByPartner = (idPartner: number) => {
    getInvoices(idPartner)
      .then((res) => {
        setInvoices(res.data);
      })
      .catch((err) => console.log(err));
  };

  const [idPartner, setIdPartner] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = (id: number) => {
    if (id === idPartner && open) {
      setOpen(false);
      setIdPartner(0);
      return;
    }
    if (id === idPartner) {
      setOpen(!open);
    } else {
      setOpen(true);
    }
    setIdPartner(id);
  };

  const handleDownload = (idPartner: number, idInvoice: number) => {
    downloadPdfInvoice(idPartner, idInvoice)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "invoice.pdf");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => console.log(error));
  };

  const handleDownloadImg = (idPartner: number) => {};

  return (
    <div className="w-full h-full border-[1px] bg-white rounded-md shadow-xl">
      <div className="flex h-10 bg-zinc-300 sticky top-0 justify-center items-center font-BarlowCondensed text-lg">
        <p className="w-[5%] ml-2">Id</p>
        <p className="w-[20%]">Nom</p>
        <p className="w-[10%]">Début</p>
        <p className="w-[15%]">Type de licence</p>
        <p className="w-[5%]">Statut</p>
        <p className="w-[5%]">Manda</p>
        <p className="w-[20%]">Visualiser</p>
        <p className="w-[5%]"></p>
        <p className="w-[5%]"></p>
        <p className="w-[5%]"></p>
        <p className="w-[5%]"></p>
      </div>
      <div className="w-full ">
        {partners.map((partner) => (
          <div key={partner.id} className=" border-b-[1px] border-gray-200">
            <button
              type="button"
              className={`flex items-center justify-between w-full py-2 text-gray-800 border-[1px] border-gray-200 focus:ring-1 focus:ring-gray-200 hover:bg-gray-100 font-light text-sm ${
                open && idPartner === partner.id ? "bg-zinc-200" : ""
              }`}
              onClick={() => {
                handleOpen(partner.id);
                handleInvoiceByPartner(partner.id);
              }}
            >
              <p className="w-[5%] flex justify-start items-center ml-2">
                {partner.id}
              </p>
              <p className="w-[20%] flex justify-start items-center">
                {partner.name}
              </p>
              <p className="w-[10%] flex justify-start items-center ml-2">
                {dateFormat(partner.startDate)}
              </p>
              <p className="w-[15%] flex justify-start items-center">
                {partner.subscription}
              </p>
              <p className="w-[5%] flex justify-start items-center ml-2">
                {partner.isSubscriptionEnabled == 1 ? "✅" : "❌"}
              </p>
              <div className="w-[5%] flex justify-start items-center">
                {partner.mandate ? (
                  <button
                    className="flex justify-center items-center"
                    onClick={() =>
                      downloadMandate(partner?.idPartnerSubscription)
                    }
                  >
                    <img src={clip} alt="clip" />
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div className="w-[20%] flex justify-start items-center gap-2">
                <button
                  className="flex justify-center items-center w-1/2 rounded-md bg-nxm-bgBlue"
                  onClick={() =>
                    navigate(`/partner/${partner?.id}/admin/users/partner`)
                  }
                >
                  Admin
                </button>
                <button
                  className="flex justify-center items-center w-1/2 rounded-md bg-nxm-bgBlue"
                  onClick={() => navigate(`/partner/${partner?.id}/customers`)}
                >
                  Manager
                </button>
              </div>
              <div className="w-[5%] flex justify-center items-center">
                <button
                  className="flex justify-center items-center"
                  onClick={() =>
                    openModal(
                      <AddUser
                        idPartner={partner.id}
                        closeModal={closeModal}
                        namePartner={partner.name}
                      />
                    )
                  }
                >
                  <img src={addUserIcon} alt="clip" />
                </button>
              </div>
              <div className="w-[5%] flex justify-center items-center">
                <button
                  className="flex justify-center items-center"
                  onClick={() =>
                    openModal(
                      <AddLogo
                        idPartner={partner.id}
                        closeModal={closeModal}
                        namePartner={partner.name}
                      />
                    )
                  }
                >
                  <img src={image} alt="clip" />
                </button>
              </div>
              <div className="w-[5%] flex justify-start items-center ml-5">
                <button className="flex justify-start items-center">
                  <img
                    src={pen}
                    alt="pen"
                    className="w-5 h-5"
                    onClick={() => {
                      setPartnerId(partner.id);
                      setDisplayDiv(true);
                      setModePartner("edit");
                      setOpenDetails(true);
                    }}
                  />
                </button>
              </div>
              <div className="w-[5%] flex justify-start items-center">
                <button
                  className="flex justify-start items-center"
                  onClick={() =>
                    openModal(
                      <DeletePartnerSuperAdmin
                        idPartner={partner.id}
                        closeModal={closeModal}
                      />
                    )
                  }
                >
                  <img src={trash} alt="trash" />
                </button>
              </div>
            </button>
            <div
              className={
                idPartner === partner.id
                  ? "block m-auto w-[90%] border-[1px] border-gray-200 rounded-md my-2"
                  : "hidden"
              }
            >
              <div className="w-full grid grid-cols-7 text-start border-b-[1px] border-b-gray-200 bg-zinc-300 h-6 px-2 text-sm">
                <p></p>
                <p>Date</p>
                <p>Numéro de facture</p>
                <p>Total remise</p>
                <p>Montant TVA</p>
                <p>Montant HT</p>
                <p>Montant TTC</p>
              </div>
              <div className="w-full h-44 flex flex-col justify-start items-center overflow-y-scroll shadow-sm">
                {invoices.length > 0 ? (
                  invoices.map((invoice) => (
                    <div
                      key={invoice.id}
                      className="w-full grid grid-cols-7 border-b-[1px] border-b-gray-200 text-sm h-6 px-2 font-light "
                    >
                      <button
                        onClick={() => {
                          handleDownload(partner.id, invoice.id);
                        }}
                      >
                        <img src={pdf} alt="pdf" className="w-4 h-5" />
                      </button>
                      <p>{dateFormat(invoice.date)}</p>
                      <p className="ml-1">{invoice.invoiceNumber}</p>
                      <p className="ml-2">{invoice.discountAmount}</p>
                      <p className="ml-2">{invoice.vatAmount}</p>
                      <p className="ml-3">{invoice.grossAmount}</p>
                      <p className="ml-4">{invoice.netAmount}</p>
                    </div>
                  ))
                ) : (
                  <div className="w-full">
                    <p className="text-center italic">
                      Aucune facture TU ES PAUVRE
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
        {displayDiv && openDetails && <CreatePartner />}
      </div>
    </div>
  );
};

export default SuperAdminPartnerTable;
