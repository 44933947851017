import { useNavigate } from "react-router-dom";
import { useSettingsStore } from "../../../../stores/Utils/settings";
import { useTokenContext } from "../../../../context/tokenContext";
import { useMfaStore } from "../../../../stores/Utils/mfa";
import Mfa from "../../../Service/Mfa";
import { useClickOutside } from "../../../../hooks/useClickOutside";
import "../../../../css/animations.css";

const Settings = () => {
  const { setShowSettings, showSettings } = useSettingsStore();
  const { setShowMfa, showMfa } = useMfaStore();
  const { isSuperAdmin } = useTokenContext();
  const navigate = useNavigate();

  // Hook
  useClickOutside([setShowSettings], "modal-Settings");

  return (
    <div className="">
      {showSettings && (
        <div
          className={`absolute top-48 left-11 md:top-54 md:left-40 2xl:top-44 2xl:left-64 z-40 right-0 shadow-lg p-4 rounded-md w-[300px] xl:w-[400px] h-[150px] ${
            isSuperAdmin === 1 ? " overflow-y-scroll" : ""
          } bg-white`}
          id="modal-Settings"
        >
          <p className="text-center font-BarlowCondensed text-lg w-full bg-nxm-headerBlue rounded-md ">
            Parametrage :
          </p>
          <button
            className="flex justify-start items-center mt-4 w-full learn-more mb-2 pb-4 "
            onClick={() => setShowMfa(true)}
          >
            <span className="circle" aria-hidden="true">
              <span className="icon arrow"></span>
            </span>
            <p className="text-md text-nxm-darkGray button-text">
              Configurer MFA
            </p>
          </button>
          {isSuperAdmin === 1 ? (
            <button
              className="flex justify-start items-center mt-4 w-full learn-more"
              onClick={() => {
                setShowSettings(false);
                navigate("/superadmin/dashboard");
              }}
            >
              <span className="circle" aria-hidden="true">
                <span className="icon arrow"></span>
              </span>
              <p className="text-md text-nxm-darkGray button-text">
                Super admin
              </p>
            </button>
          ) : null}
          {showMfa ? <Mfa /> : null}
        </div>
      )}
    </div>
  );
};

export default Settings;
